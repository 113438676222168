import {
	Typography,
	Grid,
	IconButton
} from '@material-ui/core';

import P5Wrapper from 'react-p5-wrapper';

import { useHistory, useParams } from 'react-router-dom';

import * as sketches from '../../sketches/sketches';

/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

import './sketch.css';
import { ArrowBack } from '@material-ui/icons';

export default function Sketch() {
	const { id } = useParams();
	const classes = useStyles();
	const history = useHistory();

	return (
		<div className="sketch-container">
			<Grid container direction="column" spacing={1}>
				<Grid item>
					<Grid container direction="row" alignItems="center">
						<Grid item xs={2}>
							<IconButton onClick={() => history.goBack()}>
								<ArrowBack />
							</IconButton>
						</Grid>
						<Grid item xs={8}>
							<Typography className={classes.projectsText}>
								<b>{id}</b>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography className={classes.projectsText}>
						Description
					</Typography>
				</Grid>
				<Grid item>
					<P5Wrapper sketch={sketches[id]('full')} />
				</Grid>
			</Grid>
		</div>
	);
}