/* REACT IMPORTS */
import {
	HashRouter as Router,
	Route,
	Redirect,
	Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';


/* COMPONENT IMPORTS */
import Banner from '../Banner/Banner';
import Home from '../Home/Home';
import AboutMe from '../AboutMe/AboutMe';
import Projects from '../Projects/Projects';
import Art from '../Art/Art';
import Sketch from '../Sketch/Sketch'; 


/* STYLING IMPORTS */
import theme from '../../styles/MuiTheme';

export default function App() {

  return (
		<ThemeProvider theme={theme}>
				<Router>
					<div>
						<Banner />
					</div>
					<Switch>
						<Redirect exact from="/" to="/home" />
						<Route exact path="/home">
							<Home />
						</Route>
						<Route exact path="/aboutme">
							<AboutMe />
						</Route>
						<Route exact path="/projects">
							<Projects />
						</Route>
						<Route exact path="/art">
							<Art />
						</Route>
						<Route path="/art/:id">
							<Sketch />
						</Route>
					</Switch>
				</Router>
		</ThemeProvider>
  );
}