import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Helvetica Neue',
		subtitle1: {
			fontFamily: 'Arial',
		},
		button: {
			textTransform: 'none',
		}
	},
	palette: {
		primary: {
			main: '#3964c2'
		},
		secondary: {
			main: '#42f5f2'
		},
		background: {
			main: '#fff'
		}
	},
});

export default theme;