export default function sketch(p5) {
	const WIDTH = 250;
	const HEIGHT = 250;

	const ROWS = 60;
	const COLS = 60;
	const terrainScl = 800 / ROWS;

	const SCALER = 150;
	const NOISE_SCALER = .25;
	const noiseVals = [];

	let ROTATION_SPEED;
	let ANG = 0;
	let camYSt = 1200;
	let camXSt = 600;
	let zoom = true;
	p5.setup = () => {
		p5.createCanvas(WIDTH, HEIGHT, p5.WEBGL);
		p5.colorMode(p5.HSB, 360, 100, 100, 1);
		p5.angleMode(p5.RADIANS);

		// initializes rotation speed constant - pi has to be used in here.
		ROTATION_SPEED = p5.PI / 400;

		// creates noise field for terrain
		for (let i = 0; i < COLS; i++) {
			noiseVals.push([]);
			for (let j = 0; j < ROWS; j++) {
				noiseVals[i].push(p5.noise(i * NOISE_SCALER, j * NOISE_SCALER) * SCALER);
			}
		}
	};

	p5.draw = () => {
		p5.background(0, 0, 100);

		// sets camera view
		p5.camera(0, camYSt, camXSt, 0, 0, 0, 0, 1, 0);
		p5.rotateZ(ANG)

		// draws triangles
		p5.push();
		p5.translate(-WIDTH-WIDTH/2, -HEIGHT-HEIGHT/2);
		p5.stroke(0, 0, 0);
		p5.noFill();
		for (let j = 0; j < COLS; j++) {
			p5.beginShape(p5.TRIANGLE_STRIP);
			for (let i = 0; i < ROWS; i++) {
				p5.vertex(i * terrainScl, j * terrainScl, noiseVals[i][j]);
				if (j < ROWS - 1) {
					p5.vertex(i * terrainScl, (j + 1) * terrainScl, noiseVals[i][j + 1]);
				}
			}
			p5.endShape();
		}
		p5.pop();

		// increments rotational camera
		ANG += ROTATION_SPEED;

		// increments camera zoom/flips direction
		camXSt = zoom ? camXSt - 3 : camXSt + 3;
		camYSt = zoom ? camYSt - 6 : camYSt + 6;
		if (camYSt < 400 || camYSt > 1200) {
			zoom = !zoom;
		}
	};
};