export const Pillows = (type) => (p5) => {
	const WIDTH = type === 'preview' ? 250 : 600;
	const HEIGHT = type === 'preview' ? 250 : 600;

	
	p5.setup = () => {
		p5.createCanvas(WIDTH, HEIGHT);
		p5.colorMode(p5.HSB, 360, 100, 100);
	};

	p5.draw = () => {
		p5.background(0, 100, 100);
		p5.fill(0, 0, 100);
		p5.circle(WIDTH/2, HEIGHT/2, WIDTH/10);
	};
}

export const Fluff = (type) => (p5) => {
	const WIDTH = type === 'preview' ? 250 : 600;
	const HEIGHT = type === 'preview' ? 250 : 600;
	
	p5.setup = () => {
		p5.createCanvas(WIDTH, HEIGHT);
		p5.colorMode(p5.HSB, 360, 100, 100);
	};

	p5.draw = () => {
		p5.background(0, 0, 100);
		p5.fill(0, 0, 100);
		p5.circle(WIDTH/2, HEIGHT/2, WIDTH/10);
	};
}

export const PJs = (type) => (p5) => {
	const WIDTH = type === 'preview' ? 250 : 600;
	const HEIGHT = type === 'preview' ? 250 : 600;
	
	p5.setup = () => {
		p5.createCanvas(WIDTH, HEIGHT);
		p5.colorMode(p5.HSB, 360, 100, 100);
	};

	p5.draw = () => {
		p5.background(0, 0, 50);
		p5.fill(0, 0, 100);
		p5.circle(WIDTH/2, HEIGHT/2, WIDTH/10);
	};
}
