/* IMPORT MATERIALUI */
import {
	Typography,
	Button
} from '@material-ui/core';

/* IMPORT REACT */
import { useHistory } from 'react-router-dom';


/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

export default function Banner() {
	const history = useHistory();
	const classes = useStyles();

	return (
		<header className={classes.banner}>
			<Button onClick={() => history.push('/home')}>
				<Typography>
					Home
				</Typography>
			</Button>
			<Button onClick={() => history.push('/aboutme')}>
				<Typography>
					About Me
				</Typography>
			</Button>
			<Button onClick={() => history.push('/projects')}>
				<Typography>
					Projects
				</Typography>
			</Button>
		</header>
	);
}