
/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

export default function Home() {
	const classes = useStyles();




	return (
		<div className={classes.homeContainer}>
			<img className={classes.homeChild} src="./painting.jpg" alt="painting" />
		</div>
	);
}