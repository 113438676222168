import {
	Typography
} from '@material-ui/core';

import { useState, useEffect } from 'react';
import axios from 'axios';

/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

export default function AboutMe() {
	const classes = useStyles();
	const [ aboutMeText, setAboutMeText ] = useState("");

	useEffect(() => {
		const getDisplayText = async () => {
			
			const response = await axios.get('/api/displayText')
			
			if(response.statusText !== "OK") {
				throw new Error("Error getting display text" + response.statusText);
			}

			const displayText = await response.data;
			const text = displayText.filter((item) => item.page === "aboutme");

			setAboutMeText(text[0]?.content);
		};

		getDisplayText()
	});


	return (
		<div className={classes.aboutmeContainer}>
			<Typography className={classes.aboutmeText}>
				<b>About Me</b>
			</Typography>
			<Typography className={classes.aboutmeText}>
				{aboutMeText}
			</Typography>
		</div>
	);
}