import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	banner: {
		minHeight: '10%',
		flexDirection: 'row',
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0px 0px 0px 0px',
		padding: '10px 25% 10px 25%',
		backgroundColor: '#3964c2',
		overflow: 'hidden',
	},
	homeContainer: {
		height: '100%',
		position: 'relative',
	},
	homeChild: {
		position: 'absolute',
		top: '100%',
		left: '50%',
		transform: "translate(-50%, 50%)",
	},
	aboutmeContainer: {
		height: '100%',
		textAlign: 'center',
	},
	aboutmeText: {
		margin: '0px 25% 0px 25%',
		padding: '0px 5% 0px 5%',
	},
	projectCard: {
		width: '250px',
		height: '250px',
		margin: '20px 20px 20px 20px',
		textAlign: 'center',
		position: 'relative',
	},
	projectCardText: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignIems: 'center', /** Y-axis align **/
		justifyContent: 'center', /** X-axis align **/
		position: 'absolute',
	},
	projectsText: {
		margin: '0px 5% 0px 5%',
		padding: '0px 5% 0px 5%',
	},
	projectsGrid: {

	},
	storeContainer: {
		height: '100%',
		textAlign: 'center',
	},
	storeText: {
		margin: '0px 25% 0px 25%',
		padding: '0px 5% 0px 5%',
	},
	storeGrid: {

	},
}));

export default useStyles;