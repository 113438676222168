import {
	Typography,
	Grid,
	Card,
	CardActionArea
} from '@material-ui/core';

import P5Wrapper from 'react-p5-wrapper';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import sketch from './sketch';

/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

import './projects.css';

export default function Projects() {
	const classes = useStyles();
	const history = useHistory();
	const [projectsText, setProjectsText] = useState("");

	useEffect(() => {
		const getProjectsText = async () => {

			const response = await axios.get('/api/displayText')

			if (response.statusText !== "OK") {
				throw new Error("Error getting display text" + response.statusText);
			}

			const projectsText = await response.data;
			const text = projectsText.filter((item) => item.page === "projects");

			setProjectsText(text[0]?.content);
		};

		getProjectsText()
	});

	return (
		<div className="projects-container">
			<Grid container direction="column" spacing={1}>
				<Grid item>
					<Typography className={classes.projectsText}>
						<b>Projects</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography className={classes.projectsText}>
						{projectsText}
					</Typography>
				</Grid>
				<Grid item>
					<Grid container>
						<Grid item>
							<Card className={classes.projectCard} elevation={2} >
								<CardActionArea onClick={() => history.push('/art')}>
									<Grid container direction="column">
										<Grid item>
											<Typography className={classes.projectCardText}>
												Art
											</Typography>
										</Grid>
										<Grid item>
											<P5Wrapper sketch={sketch} />
										</Grid>
									</Grid>
								</CardActionArea>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}