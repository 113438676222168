import {
	Typography,
	Grid,
	IconButton
} from '@material-ui/core';


import { useHistory } from 'react-router-dom';

import SketchPreview from './SketchPreview';

/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

import './art.css';
import { ArrowBack } from '@material-ui/icons';

import * as test from '../../sketches/sketches';



const sketches = Object.keys(test);

export default function Art() {
	const classes = useStyles();
	const history = useHistory();

	return (
		<div className="art-container">
			<Grid container direction="column" spacing={1}>
				<Grid item>
					<Grid container direction="row" alignItems="center">
						<Grid item xs={2}>
							<IconButton onClick={() => history.goBack()}>
								<ArrowBack />
							</IconButton>
						</Grid>
						<Grid item xs={8}>
							<Typography className={classes.projectsText}>
								<b>Art</b>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography className={classes.projectsText}>
						Generative art is cool
					</Typography>
				</Grid>
				<Grid item>
					<Grid container>
						{sketches.map((sketch) => {
							return (
								<Grid item key={sketch}>
									<SketchPreview id={sketch} />
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}