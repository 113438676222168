import {
	Typography,
	Grid,
	Card,
	CardActionArea
} from '@material-ui/core';

import P5Wrapper from 'react-p5-wrapper';

import { useHistory } from 'react-router-dom';

import * as sketches from '../../sketches/sketches';

/* IMPORT STYLING */
import useStyles from '../../styles/useStyles';

import './art.css';

export default function SketchPreview({ id }) {
	const classes = useStyles();
	const history = useHistory();


	return (
			<Card className={classes.projectCard}>
				<CardActionArea onClick={() => history.push(`/art/${id}`)}>
					<Grid container direction="column">
						<Grid item>
							<Typography className={classes.projectCardText}>
								{id}
							</Typography>
						</Grid>
						<Grid item>
							<P5Wrapper sketch={sketches[id]('preview')} />
						</Grid>
					</Grid>
				</CardActionArea>
			</Card>
	);
}